.SectionHeader {
  // Add bottom margin if element below
  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  &__subtitle {
    // Subtitle text generally isn't very long
    // so usually looks better to limit width.
    max-width: 700px;
    // So we can have max-width but still
    // have alignment controlled by text-align.
    display: inline-block;

    @media all and (max-width: 991px) {
      > ul {
        text-align: left;
      }
    }
  }
}

.SectionHeaderHowTo {
  // Add bottom margin if element below
  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  &__subtitle {
    display: inline-block;

    @media all and (max-width: 991px) {
      > ul {
        text-align: left;
      }
    }
  }
}

.HowToContainer {
  @media all and (min-width: 991px) {
    padding-left: 3rem !important;
    margin: 3rem !important;
  }
}