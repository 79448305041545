.FaqItem {
  cursor: pointer;
  border-bottom: 1px solid #efefef;
  &:last-child {
    border-bottom: none;
  }


  .faqUl {
    @media all and (min-width: 991px) {
      margin: 1.5rem;
    }

      > li {
        font-size: 1.2rem;

        > span {
          font-size: 1rem;
        }
      }
  }
}

.howToLi {
  max-width: 500px;
}

.howToLi2 {
  max-width: 700px;
}
